<template>
  <body class="overflow-hidden">
    <v-app-bar class="mb-2" absolute color="white" scroll-target="#scrolling-techniques-7">
      
      <v-app-bar-nav-icon color="#282c2e" @click.stop="drawer = !drawer"><fai icon="bars"/></v-app-bar-nav-icon>

      <v-toolbar-title> <v-img :src="$store.getters.get_logo"></v-img></v-toolbar-title>
  
        <v-spacer></v-spacer>
  
        <v-btn icon @click="$router.push('/notifications/'+1)">
          <v-badge :content="$store.getters.get_notice.toString()"><fai icon="bell"/></v-badge>
        </v-btn>
  
        <v-btn icon @click.stop="$store.dispatch('logout')">
         <fai icon="lock"/>
        </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-6 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->
      <div :style="{marginTop:'2.6em'}">
      <div>
        <v-select v-model="type" label="Duration Type" :items="duration_type" solo flat dense></v-select>
      </div>

      <div class="contx">
        <v-date-picker :type="c_type" v-model="duration" show-current>
          
        </v-date-picker>
        <v-btn fab class="mx-2" color="primary" dark @click="loadData"><fai icon="refresh"/></v-btn>
        <div> 
          <v-progress-linear indeterminate v-show="progress"></v-progress-linear>
          <!--charts-->
          <div>
            
            <div class="mnx">
              <v-card class="mx-2 cd" color="red" dark>
              <v-card-title>Total Withdrawals:</v-card-title>
              <v-card-text>{{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(total_w)}}</v-card-text>
            </v-card>
            <v-card class="mx-2 cd" color="green" dark>
              <v-card-title>Total Deposits:</v-card-title>
              <v-card-text>{{new Intl.NumberFormat('en-NG',{style:'currency',currency:'NGN'}).format(total_d)}}</v-card-text>
            </v-card>
            </div>
          </div>
        </div>
      </div>
      </div>

       <v-dialog persistent v-model="notification" maxWidth="300">
        <v-card>
          <v-img :src="img"/>
          <v-card-title :style="{fontSize:'16px'}">{{notice}}</v-card-title>
          <v-card-actions class="d-flex justify-center">
            <div :style="{display:'flex',justifyContent:'center',padding:'2px'}">
            <v-btn @click="notification=!notification" class="mx-2" large rounded>Close</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--
  END OF WEB PAGE
      -->
    </v-container>

<v-navigation-drawer
        v-model="drawer"
        absolute
        left
        temporary
        dark
        color="#fe7b00"
      >
        <v-list
          nav
          dense
        >
         <div class="d-flex justify-start" :style="{fontSize:'50px',color:'#282c2e'}">
            <fai icon="dashboard"/>
            <span class="title mx-2 mt-4" :style="{color:'#282c2e',fontStyle:'italic'}">welcome admin</span>
         </div>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item v-for="(item , index) in sideMenu" :key="index" @click="switchMenu(item.name)">
              <v-list-item-icon><fai :icon="item.icon" class="mt-1"/></v-list-item-icon>
              <v-list-item-content>{{item.name}}</v-list-item-content>
            </v-list-item>
  
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

  </body>
  
</template>

<script>
 // import axios from 'axios'
export default {
    data:()=>{
        return{
            overlay:false,
            drawer: false,
            selectedMenu:'Home',
            sideMenu:[
               
            ],
            duration_type:[
            {text:'Daily' , value:'daily'},
            {text:'Monthly' , value:'monthly'},
            ],
            img:require('../assets/undraw_questions_re_1fy7.svg'),
            notice:'',
            notification:false,
            c_type:'date',
            type:undefined,
            duration:undefined,
            progress:false,
            total_w:0,
            total_d:0

            
        }
    },
    components:{

    },
    methods:{
    loadData(){
      if(this.type===undefined){
        this.notice="Please select a duration type!"
        this.notification=true;
      }
      else if(this.duration===undefined){
        this.notice="Please select a period from the date picker!"
        this.notification=true;
      }
      else{
        this.progress=true;
        this.notice='';
        this.axios({
        url:this.$store.getters.get_api+'fetchFinanceStatement/'+this.type+'/'+this.duration,
        method:'get',
        headers:{
                "Access-Control-Allow-Origin":"*",
               'Authorization':`Bearer ${this.$store.getters.get_token}`
       }
      }).then((response)=>{
        const data = response.data;
        if(!data.error){
          this.total_w = data.total_withdrawal;
          this.total_d = data.total_deposit
          /*if(this.type="monthly"){
            this.m_value=data.
            this.m_label=data.
          }*/
        }
        this.progress=false;
      }).catch((error)=>{
        return error;
      })
      }
    },
     switchMenu(name){
        if(this.$router.currentRoute.path!=='/'+name.toLowerCase())
        {
          this.$router.push('/'+name.toLowerCase());
        }
     },
     
     logout(){
      
      this.axios({
        url:this.$store.getters.get_api+"adminLogout",
        method:"get",
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Authorization':`Bearer ${this.$store.getters.get_token}`
        }
      }).then((res)=>{
        const data = res.data;
        if(!data.error){
          this.$store.commit("set_token" , undefined);
          this.$router.push("/")
        }
      }).catch((error)=>{
        return error
      })
     }
    },
    created(){

    },
    mounted(){
      this.sideMenu=this.$store.getters.get_menu;
     // this.getAllUsers(1)
       this.$store.dispatch('totalNotice');
       this.$store.dispatch('totalTransaction')
    },
    watch:{
       
        //END OF WATCH
        type(value){
          if(value==='daily')
          {
            this.c_type='date';
          }
          else{
            this.c_type='month'
          }
        }
    }
}
</script>
<style scoped>
*{
    border:0px;
}
.overflow-y-auto{
  min-width:100%;
  min-height:100%;
  
}
.overflow-hidden{
  background:#e0e0e0;
  min-height: 100%;
}
.icon-1{
    font-size: 50px;
}

@media screen and (min-width: 640px){
  .contx{
    display: flex;
    flex-flow: row wrap;
  }
   .mnx{
    display:flex;
    padding:6px;
    flex-flow: row wrap;
  }
}

@media screen and (max-width: 600px){
  .contx{
    display: flex;
    flex-flow: column;
  }

  .mnx{
    display:flex;
    padding:6px;
    flex-flow: column;

  }
  .cd{
    width:100%;
    margin-top:4px;
  }
}
</style>